// import { BrowserRouter, Routes, Route } from "react-router-dom";

// import MainPage from "./pages/MainPage";
// import { useTheme } from "./providers/ThemeProvider";
// import Header from "./components/Header/Header";
// import Footer from "./components/Footer/Footer";
// import AboutPage from "./pages/AboutPage";
// import StandardtsPage from "./pages/StandardtsPage";
// import ContactPage from "./pages/ContactPage";
// import FaqPage from "./pages/FaqPage";
// import TokenPage from "./pages/TokenPage";

// import CookieNotice from "./pages/CookieNotice";
// import ListCountriesPage from "./pages/ListCountriesPage";
// import RiskWarningPage from "./pages/RiskWarningPage";
// import AllProductsPage from "./pages/AllProductsPage";
// import PrivacyNoticePage from "./pages/PrivacyNoticePage";
// import TermsOfUsePage from "./pages/TermsOfUsePage";
// import CreateToken from "./pages/CreateToken";
// import MobileMenu from "./components/MobileMenu/MobileMenu";
// import ARTPage from "./pages/ARTPage";
// import EmtPage from "./pages/EmtPage";
// import UnboundPage from "./pages/UnboundPage";
// import RegulationPage from "./pages/RegulationPage";
// import СlassifyingPage from "./pages/СlassifyingPage";
// import TermsPage from "./pages/TermsPage";

// import TariffsPage from "./pages/TariffsPage";
// import TariffsTermPage from "./pages/TariffsTermPage";
// import BlockchainPage from "./pages/BlockchainPage";
// import ValidatorsPage from "./pages/ValidatorsPage";
// import CryptoATMsPage from "./pages/CryptoATMsPage";
// import PresalePage from "./pages/PresalePage";
// import MerchantPage from "./pages/MerchantPage";
// import MerchantConfigPage from "./pages/MerchantConfigPage";
// import LaunchTokenPage from "./pages/LaunchTokenPage";
// import ExchangeDescPage from "./pages/ExchangeDescPage";
// import { useEffect, useState } from "react";
// import "./i18n";

// import i18n from "./i18n";
// function App() {
//   const { theme } = useTheme();

//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

//   const toggleMobileMenu = () => {
//     setIsMobileMenuOpen(!isMobileMenuOpen);
//   };
//   useEffect(() => {
//     const savedLanguage = localStorage.getItem("selectedLanguage");
//     if (savedLanguage) {
//       i18n.changeLanguage(savedLanguage);
//     }
//   }, []);

//   return (
//     <div className={`${theme} w-full`}>
//       <div className="dark:bg-black w-full">
//         <BrowserRouter>
//           <Header toggleMobileMenu={toggleMobileMenu} isMobileMenuOpen={isMobileMenuOpen} />
//           <MobileMenu isOpen={isMobileMenuOpen} onClose={() => setIsMobileMenuOpen(false)} />
//           <Routes>
//             <Route path="*" element={<MainPage />} />
//             <Route path="popularTokens" element={<AllProductsPage />} />
//             <Route path="about" element={<AboutPage />} />
//             <Route path="legal" element={<StandardtsPage />} />
//             <Route path="contact" element={<ContactPage />} />
//             <Route path="faq" element={<FaqPage />} />
//             <Route path="cookieNotice" element={<CookieNotice />} />
//             <Route path="termsOfUse" element={<TermsOfUsePage />} />
//             <Route path="privacyNotice" element={<PrivacyNoticePage />} />
//             <Route path="listOfProhibitedCountries" element={<ListCountriesPage />} />
//             <Route path="RiskWarning" element={<RiskWarningPage />} />
//             <Route path="ARTPage" element={<ARTPage />} />
//             <Route path="EmtPage" element={<EmtPage />} />
//             <Route path="UnboundPage" element={<UnboundPage />} />
//             <Route path="TariffsPage" element={<TariffsPage />} />
//             <Route path="BlockchainPage" element={<BlockchainPage />} />
//             <Route path="Forvalidators" element={<ValidatorsPage />} />
//             <Route path="TariffsTermPage" element={<TariffsTermPage />} />
//             <Route path="СlassifyingPage" element={<СlassifyingPage />} />
//             <Route path="RegulationPage" element={<RegulationPage />} />
//             <Route path="Createtoken" element={<CreateToken />} />
//             <Route path="TermsPage" element={<TermsPage />} />
//             <Route path="CryptoATMs" element={<CryptoATMsPage />} />
//             <Route path="token/:tiker" element={<TokenPage />} />
//             <Route path="Presale" element={<PresalePage />} />
//             <Route path="Merchant" element={<MerchantPage />} />
//             <Route path="MerchantConfig" element={<MerchantConfigPage />} />
//             <Route path="LaunchToken" element={<LaunchTokenPage />} />
//             <Route path="ExchangeDesc" element={<ExchangeDescPage />} />
//           </Routes>
//           <Footer />
//         </BrowserRouter>
//       </div>
//     </div>
//   );
// }

// export default App;

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { useEffect, useState } from "react";
import "./i18n";
import i18n from "./i18n";
import React, { Suspense, lazy } from "react";

import { useTheme } from "./providers/ThemeProvider";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import MobileMenu from "./components/MobileMenu/MobileMenu";

const MainPage = lazy(() => import("./pages/MainPage"));
const AllProductsPage = lazy(() => import("./pages/AllProductsPage"));
const AboutPage = lazy(() => import("./pages/AboutPage"));
const StandardtsPage = lazy(() => import("./pages/StandardtsPage"));
const ContactPage = lazy(() => import("./pages/ContactPage"));
const FaqPage = lazy(() => import("./pages/FaqPage"));
const CookieNotice = lazy(() => import("./pages/CookieNotice"));
const TermsOfUsePage = lazy(() => import("./pages/TermsOfUsePage"));
const PrivacyNoticePage = lazy(() => import("./pages/PrivacyNoticePage"));
const ListCountriesPage = lazy(() => import("./pages/ListCountriesPage"));
const RiskWarningPage = lazy(() => import("./pages/RiskWarningPage"));
const ARTPage = lazy(() => import("./pages/ARTPage"));
const EmtPage = lazy(() => import("./pages/EmtPage"));
const UnboundPage = lazy(() => import("./pages/UnboundPage"));
const TariffsPage = lazy(() => import("./pages/TariffsPage"));
const BlockchainPage = lazy(() => import("./pages/BlockchainPage"));
const ValidatorsPage = lazy(() => import("./pages/ValidatorsPage"));
const TariffsTermPage = lazy(() => import("./pages/TariffsTermPage"));
const СlassifyingPage = lazy(() => import("./pages/СlassifyingPage"));
const RegulationPage = lazy(() => import("./pages/RegulationPage"));
const CreateToken = lazy(() => import("./pages/CreateToken"));
const TermsPage = lazy(() => import("./pages/TermsPage"));
const CryptoATMsPage = lazy(() => import("./pages/CryptoATMsPage"));
const TokenPage = lazy(() => import("./pages/TokenPage"));
const PresalePage = lazy(() => import("./pages/PresalePage"));
const MerchantPage = lazy(() => import("./pages/MerchantPage"));
const MerchantConfigPage = lazy(() => import("./pages/MerchantConfigPage"));
const LaunchTokenPage = lazy(() => import("./pages/LaunchTokenPage"));
const ExchangeDescPage = lazy(() => import("./pages/ExchangeDescPage"));
const FormPage = lazy(() => import("./pages/FormPage"));

function App() {
  const { theme } = useTheme();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, []);
  

  return (
    <div className={`${theme} w-full`}>
      <div className="dark:bg-black w-full">
        <BrowserRouter>
          <Header toggleMobileMenu={toggleMobileMenu} isMobileMenuOpen={isMobileMenuOpen} />
          <MobileMenu isOpen={isMobileMenuOpen} onClose={() => setIsMobileMenuOpen(false)} />
          <Suspense fallback={<div className="w-full h-[100vh] flex justify-center items-center bg-dark">Loading...</div>}>
            <Routes>
              <Route path="*" element={<MainPage />} />
              <Route path="popularTokens" element={<AllProductsPage />} />
              <Route path="about" element={<AboutPage />} />
              <Route path="legal" element={<StandardtsPage />} />
              <Route path="contact" element={<ContactPage />} />
              <Route path="faq" element={<FaqPage />} />
              <Route path="cookieNotice" element={<CookieNotice />} />
              <Route path="termsOfUse" element={<TermsOfUsePage />} />
              <Route path="privacyNotice" element={<PrivacyNoticePage />} />
              <Route path="listOfProhibitedCountries" element={<ListCountriesPage />} />
              <Route path="RiskWarning" element={<RiskWarningPage />} />
              <Route path="ARTPage" element={<ARTPage />} />
              <Route path="EmtPage" element={<EmtPage />} />
              <Route path="UnboundPage" element={<UnboundPage />} />
              <Route path="TariffsPage" element={<TariffsPage />} />
              <Route path="BlockchainPage" element={<BlockchainPage />} />
              <Route path="Forvalidators" element={<ValidatorsPage />} />
              <Route path="TariffsTermPage" element={<TariffsTermPage />} />
              <Route path="СlassifyingPage" element={<СlassifyingPage />} />
              <Route path="RegulationPage" element={<RegulationPage />} />
              <Route path="Createtoken" element={<CreateToken />} />
              <Route path="TermsPage" element={<TermsPage />} />
              <Route path="CryptoATMs" element={<CryptoATMsPage />} />
              <Route path="token/:tiker" element={<TokenPage />} />
              <Route path="Presale" element={<PresalePage />} />
              <Route path="Merchant" element={<MerchantPage />} />
              <Route path="MerchantConfig" element={<MerchantConfigPage />} />
              <Route path="LaunchToken" element={<LaunchTokenPage />} />
              <Route path="ExchangeDesc" element={<ExchangeDescPage />} />
              {/* <Route path="FormPage" element={<FormPage />} /> */}
            </Routes>
          </Suspense>

          <Footer />
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
