import translationEn from "./public/locales/en/translation.json";
import translationRu from "./public/locales/ru/translation.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: { }, 
  lng: "en", 
  fallbackLng: "en",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});

i18n.addResourceBundle("en", "translation", translationEn);

const loadTranslations = async (lng) => {
  switch (lng) {
    case "en":
      const translationEn = await import("./public/locales/en/translation.json");
      i18n.addResourceBundle("en", "translation", translationEn.default);
      break;
    case "ru":
      const translationRu = await import("./public/locales/ru/translation.json");
      i18n.addResourceBundle("ru", "translation", translationRu.default);
      break;
    case "cs":
      const translationCs = await import("./public/locales/cs/translation.json");
      i18n.addResourceBundle("cs", "translation", translationCs.default);
      break;
    case "pl":
      const translationPl = await import("./public/locales/pl/translation.json");
      i18n.addResourceBundle("pl", "translation", translationPl.default);
      break;
    case "de":
      const translationDe = await import("./public/locales/de/translation.json");
      i18n.addResourceBundle("de", "translation", translationDe.default);
      break;
    case "fr":
      const translationFr = await import("./public/locales/fr/translation.json");
      i18n.addResourceBundle("fr", "translation", translationFr.default);
      break;
    case "kk":
      const translationKk = await import("./public/locales/kk/translation.json");
      i18n.addResourceBundle("kk", "translation", translationKk.default);
      break;
    case "zh":
      const translationZh = await import("./public/locales/zh/translation.json");
      i18n.addResourceBundle("zh", "translation", translationZh.default);
      break;
    case "ar":
      const translationAr = await import("./public/locales/ar/translation.json");
      i18n.addResourceBundle("ar", "translation", translationAr.default);
      break;
    case "uk":
      const translationUk = await import("./public/locales/uk/translation.json");
      i18n.addResourceBundle("uk", "translation", translationUk.default);
      break;
    case "es":
      const translationEs = await import("./public/locales/es/translation.json");
      i18n.addResourceBundle("es", "translation", translationEs.default);
      break;
    case "pt":
      const translationPt = await import("./public/locales/pt/translation.json");
      i18n.addResourceBundle("pt", "translation", translationPt.default);
      break;
    case "hi":
      const translationHi = await import("./public/locales/hi/translation.json");
      i18n.addResourceBundle("hi", "translation", translationHi.default);
      break;
    case "it":
      const translationIt = await import("./public/locales/it/translation.json");
      i18n.addResourceBundle("it", "translation", translationIt.default);
      break;
    default:
      break;
  }
};

const changeLanguage = async (lng) => {
  if (!i18n.hasResourceBundle(lng, "translation")) {
    await loadTranslations(lng);
  }
  i18n.changeLanguage(lng);
};

export { changeLanguage };
export default i18n;
